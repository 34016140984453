import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import meeting_bg from '../assets/images/meeting_bg.png'
import interview_bg from '../assets/images/interview_bg.png'
import verify_bg from '../assets/images/verify_bg.jpg'
import coupon_bg from '../assets/images/coupon_bg.jpg'
import apply_bg from '../assets/images/apply_bg.png'
import webuy_bg from '../assets/images/webuy_bg.jpg'

class HomeIndex extends React.Component {
    render() {
        return (
            <Layout>
                <Helmet
                    title="红鼎石科技"
                    meta={[
                        { name: 'description', content: '四川红鼎石科技有限公司' },
                        { name: 'keywords', content: '红鼎石, 红鼎石科技' },
                    ]}
                ></Helmet>
                <Banner />
                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{ backgroundImage: `url(${meeting_bg})` }}>
                            <header className="major">
                                <h3>快会议 InstMeet</h3>
                                <h5>基于 WebRTC 的视频会议系统，可以在微信、QQ等聊天中开启会议（不需要小程序或App）；提供手机App、PC程序、网页等多种安装及免安装的运行方式；小于0.4秒的延迟；服务器端自动录制</h5>
                            </header>
                            <Link to="https://instmeet.cn" className="link primary"></Link>
                        </article>
                        <article style={{ backgroundImage: `url(${interview_bg})` }}>
                            <header className="major">
                                <h3>面试了</h3>
                                <h5>高校远程面试系统，多位远程面试官；面试生排队系统；人脸拍照及身份证验证；面试生选题；服务器端自动录制</h5>
                            </header>
                            <Link to="https://iv.stream5g.cn" className="link primary"></Link>
                        </article>
                        <article style={{ backgroundImage: `url(${verify_bg})` }}>
                            <header className="major">
                                <h3>身份核验</h3>
                                <h5>快速核验上万名高校学生的照片，并生成包含相似度等信息的 PDF 报告</h5>
                            </header>
                            <Link to="https://sv.shijibaina.com" className="link primary"></Link>
                        </article>
                        <article style={{ backgroundImage: `url(${coupon_bg})` }}>
                            <header className="major">
                                <h3>友友代金券</h3>
                                <h5>以付款为入口、为企业增加回头客及招揽新客户的微信小程序</h5>
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>
                        <article style={{ backgroundImage: `url(${apply_bg})` }}>
                            <header className="major">
                                <h3>友友申请</h3>
                                <h5>一款工具型微信小程序：建立项目、创建表单、友友相传、申请、审核、打赏</h5>
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>
                        <article style={{ backgroundImage: `url(${webuy_bg})` }}>
                            <header className="major">
                                <h3>友友时代</h3>
                                <h5>一款集即时通讯、群聊、晒晒、活动、购物为一体的社群经济 App</h5>
                            </header>
                            {/* <Link to="/landing" className="link primary"></Link> */}
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>公司介绍</h2>
                            </header>
                            <p>
                                四川红鼎石科技有限公司于2015年在成都高新区成立，实缴注册资金300万人民币。
                            </p>
                        </div>
                    </section>
                </div>
            </Layout >
        )
    }
}

export default HomeIndex
