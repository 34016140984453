import React from 'react'

const Banner = props => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>四川红鼎石科技有限公司</h1>
            </header>
            <div className="content">
                <h4>
                    实时音视频和对象存储技术提供商，专注于开发和运营跨平台（移动APP、桌面程序、H5网页、小程序等）的新一代产品及服务
                </h4>
            </div>
        </div>
    </section>
)

export default Banner
